import React from "react";
import { useState,useEffect } from "react";
import {Elements,useStripe,useElements} from '@stripe/react-stripe-js';
import Loader from "../../images/loader.png";
import {loadStripe} from '@stripe/stripe-js';
import { getPaymentRetakeIntent } from "../../services/api";
import Checkout from './Retakecheckout';



const stripePromise = loadStripe('pk_test_51Op5bKJnwLc81YFtQcBbFAsiax0GueRMQee7HyGM36dlJHGza0y7mPs9BGW3g1Kqplh5hzddKqn12sPUqwqh15U800RTVQ1g0E'
);
const Recharge =()=>{
	const [isloader , setIsLoader] = useState(false);
	const [clientSecret, setClientSecret] = useState("");

	useEffect(()=>{
	
		initiateStripe();
	
	},[])

	const initiateStripe=async()=>{
		const response = await getPaymentRetakeIntent();
		setClientSecret(response.data);
	}

     return (
		<div className="container  min-vh-100 mt-4">
			{isloader &&
		<div class="loader position-fixed top-0 bottom-0 start-0 end-0 bg-dark">
		<span class="position-absolute top-50 start-50 translate-middle"><img src={Loader} alt="Loader"  /></span>
		</div>
		}
		 <div className="row  mt-4">
		 	<div class="col-lg-12 ps-lg-5 mt-4 p-4">
				{clientSecret?
					<Elements stripe={stripePromise} options={{ clientSecret }}>
						<Checkout clientSecret = {clientSecret} setIsLoader={setIsLoader}/> 
					</Elements>:<></>
				}
			</div>
		 </div>

		</div>
	 );
}

export default Recharge;