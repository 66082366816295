import React from "react";
import { useState } from "react";
import Aside from "./layout/Aside";
import Content from "./Content";
import { Link } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";

const Dashboard = (props) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const[ quizalert, setQuizalert] = useState(false);
  const [sidebarlock, setSidebarlock] = useState(true);
  return (
    <>
    <div className="row align-items-stretch">
        <div className="col-lg-4 col-md-5">
          <div class="progressbarcard p-3">
            <h3>Overall Progress</h3>
            <ProgressBar 
              completed={sessionStorage.getItem('testResult')> 0 ? '100':props.progress} 
              borderRadius = "50px"
              bgColor="#00AAAA"
              labelSize="12px"
              labelAlignment="outside"
              isLabelVisible="true"
              animateOnRender="true"
            ></ProgressBar>
            <span>{sessionStorage.getItem('testResult')> 0 ? '100':props.progress}% Completed</span>
          </div>
          
          <Aside
            topicstatus={props.topicstatus}
            setQuizalert = {setQuizalert}
            sections={props.sections}
            setPages={props.setPages}
            setTopic={props.setTopic}
            setShowSuccess = {setShowSuccess}
            showSuccess = {showSuccess}
            sidebarlock = {sidebarlock}
            setCurrentSection = {props.setCurrentSection}
          />
          
        </div>
        <div className="col-lg-8 col-md-7">
          <Content
            pages={props.pages}
            quizalert = {quizalert}
            setQuizalert = {setQuizalert}
            setSidebarlock = {setSidebarlock}
            sections={props.sections}
            topicstatus={props.topicstatus}
            setPages={props.setPages}
            setCurrentSection = {props.setCurrentSection}
            topic={props.topic}
            setReload={props.setReload}
            reload={props.reload}
            setTopic={props.setTopic}
            showSuccess = {showSuccess}
            setShowSuccess = {setShowSuccess}
            refreshSections={props.refreshSections} 
            currentSection = {props.currentSection}
            courseStatus = {props.courseStatus}
            startcourse = {props.startcourse}
            setStartCourse = {props.setStartCourse}
          />
        </div>
        </div>
     
    </>
  );
};
export default Dashboard;
