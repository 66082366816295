import React from "react";
import {PaymentElement} from '@stripe/react-stripe-js';
import { useState } from "react";
import { register } from "../../services/api";
import { useNavigate } from "react-router-dom";

import {Elements,useStripe,useElements} from '@stripe/react-stripe-js';

const Checkout = (props)=>{
	const navigate = useNavigate();
	const [loader, setLoader] = useState(false);

	const elements = useElements();
	const stripe = useStripe(); 

	const handleSubmit = async (e) => {
		e.preventDefault();

		setLoader(true);
		const paymentElement = elements.getElement(PaymentElement);
		if (!paymentElement) {
		return;
		}
		const { error, paymentIntent } = await stripe.retrievePaymentIntent(props.clientSecret);
		
		if (paymentIntent.status !== 'succeeded') {
			const { error: confirmError, confirmpaymentIntent: confirmedPaymentIntent } = await stripe.confirmPayment({
				elements,
				confirmParams: {
				  return_url: 'https://your-website.com/payment-success',
				},
				redirect: "if_required",
			  });
			  if (confirmError) {
				setLoader(false);
				return;
			  } 
		}
		try {
			const response = await register(props.formData);
			if (response.data.token) {
			  sessionStorage.setItem('token', response.data.token);
			  sessionStorage.setItem('user_id', response.data.user.id);
			  sessionStorage.setItem('user_name', response.data.user.name);
			  sessionStorage.setItem('user_email', response.data.user.email);
			  sessionStorage.setItem('user',JSON.stringify(response.data.user));
			  navigate('/course/home');
			} else {
			  setLoader(false);
			}
		  } catch (error) {
			console.error('Error during registration:', error);
			setLoader(false);
		  }

		
	  };
	  
	return(
		<>
		<PaymentElement />
		{loader?<span class=" mt-3 checkout-loader"></span>:<></>}
		<button name="btn" onClick={handleSubmit} class="btn btn-primary-s px-4 py-3 my-4 w-100 text-white">PAY</button>
		</>
	);
}
export default Checkout;