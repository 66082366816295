import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import Checkout from './Checkout';
import imageAside from "../../images/form-aside.png";
import Loader from '../../images/loader.png';
import {Elements,useStripe,useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { getPaymentIntent, userValidate } from "../../services/api";

const stripePromise = loadStripe('pk_test_51Op5bKJnwLc81YFtQcBbFAsiax0GueRMQee7HyGM36dlJHGza0y7mPs9BGW3g1Kqplh5hzddKqn12sPUqwqh15U800RTVQ1g0E'
);
const Register=()=>{
	const navigate = useNavigate();
	const [isloader , setIsLoader] = useState(false);
	const [clientSecret, setClientSecret] = useState("");

	useEffect(() => {
	  const isAuthenticated = sessionStorage.getItem('token');
	  if (isAuthenticated) {
		navigate('/course/home');
	  }
	}, []);
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		password: ''
	  });
	const [errors, setErrors] = useState({});
  	const [successMessage, setSuccessMessage] = useState('');
	  const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData({
		  ...formData,
		  [name]: type === 'checkbox' ? checked : value
		});
	  };
	  const validate = () => {
		let errors = {};
	
		if (!formData.name) {
		  errors.name = 'Name is required';
		}
		if (!formData.email) {
		  errors.email = 'Email is required';
		} else if (!/\S+@\S+\.\S+/.test(formData.email)) {
		  errors.email = 'Email address is invalid';
		}
		if (!formData.password) {
		  errors.password = 'Password is required';
		} else if (formData.password.length < 6) {
		  errors.password = 'Password must be at least 6 characters';
		}
		setErrors(errors);
	
		return Object.keys(errors).length === 0;
	  };

	

	  const handleNext = async (e) => {
		e.preventDefault();
		if (validate()) {

			try {

				const response = await userValidate(formData);
		  
				if (response.data) {
					const paymentResponse = await getPaymentIntent();
					setClientSecret(paymentResponse.data);
				
				}
			  } catch (error) {
				if (error.response && error.response.status === 422) {
					// Merge server-side errors with client-side errors
					const serverErrors = error.response.data.errors;
					setErrors({
						...serverErrors,  // Merge server-side errors
					});
				}
			  }
			
		}
	  };

	  const responseMessage = async(response) => {
		const decodedToken = jwtDecode(response.credential);
	
		if(decodedToken){
		    setFormData((prevFormData) => ({
				...prevFormData,
				email: decodedToken.email || prevFormData.email,  
				name: decodedToken.name || prevFormData.name,     
				password: prevFormData.sub || 'google-oauth' 
			  }));
		   const response = await getPaymentIntent();
		   setClientSecret(response.data);
		 
		//    const response = await googleLogin(formData);
		//    if(response.data.token){
		// 	   setSuccessMessage('Login successful!');
		// 	   sessionStorage.setItem('token', response.data.token);
		// 	   sessionStorage.setItem('user_id', response.data.user.id);
		// 	   sessionStorage.setItem('user_name', response.data.user.name);
		// 	   sessionStorage.setItem('user_email', response.data.user.email);
			   
		// 	   const redirectPath = sessionStorage.getItem('redirectPath') || '/';
		// 	   sessionStorage.removeItem('redirectPath');
		// 	   navigate(redirectPath);
		//    }
		//    else{
		// 	   setErrors({ apiError: response.data.error });
		//    }
		}
   };
   const errorMessage = (error) => {
	   console.log(error);
   };
	return (
		<div className="login-register">
			{isloader &&
		<div class="loader position-fixed top-0 bottom-0 start-0 end-0 bg-dark">
		<span class="position-absolute top-50 start-50 translate-middle"><img src={Loader} alt="Loader"  /></span>
		</div>
		}
		
		<div class="container d-flex align-items-center mvh-100">
		<Link className="back-btn mr-3 " to="/"><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>

			<section class="login-registration rounded-3 overflow-hidden shadow bg-light">
				<div class="row align-items-stretch">
				<div class="col-md-6 bg-secondary-s text-center text-white">
					<div class="p-5">
					<h1 class="h3">Let's Get Started</h1>
					<p>Sign Up and get access to all the features of Florida Driving Permit <br></br><br></br>
					{errors.apiError && <span className="text-danger">{errors.apiError}</span>}
					{successMessage && <span className="text-success">{successMessage}</span>}</p>
					{clientSecret?( 
							<Elements stripe={stripePromise} options={{ clientSecret }}>
								<Checkout clientSecret = {clientSecret} setErrors = {setErrors} setIsLoader={setIsLoader} formData = {formData}/> 
							</Elements>):(
							<form class="text-dark py-4" onSubmit={handleNext}>
								<div class="row">
								<div class="col-lg-6">
									<div class="form-floating mt-4">
									<input type="text" class="form-control" id="fname" placeholder=" " name="name" value={formData.name}  onChange={handleChange} />
									<label for="fname">Name</label>
									</div>
								</div>
								<div class="col-lg-6">
									<div class="form-floating mt-4">
									<input type="email" class="form-control" id="email" placeholder=" " name="email" value={formData.email} onChange={handleChange}/>
									<label for="email">Email Address</label>
									{errors.email && <span className="text-danger">{errors.email}</span>}
									</div>
								</div>
								<div class="col-lg-6">
									<div class="form-floating mt-4">
									<input type="password" class="form-control" id="password" placeholder=" "  name="password" value={formData.password} onChange={handleChange} autoComplete="current-password"/>
									<label for="password">Password</label>
									{errors.password && <span className="text-danger">{errors.password}</span>}
									</div>
								</div>
								<div class="col-12">
									<div class="form-check mt-4 text-start d-flex gap-2">
									<input class="form-check-input mt-0" type="checkbox" id="terms" />
									<label class="form-check-label text-white" for="terms">I agree to <a href="javascript:;" class="text-primary-s">Terms &amp; Conditions</a></label>
									</div>
								</div>
								<div class="col-12">
									<button name="btn" class="btn btn-primary-s px-4 py-3 my-4 w-100 text-white">Register</button>
									<p class="text-white">Already a member? <Link to="/login" class="text-primary-s fw-bold">LOGIN</Link></p>
								</div>
								<div class="col-lg-12 mt-3">
									<GoogleLogin class="btn btn-danger px-4 py-2 my-4 w-100" onSuccess={responseMessage} onError={errorMessage} />
								</div>
								</div>
							</form> 
						)}
					</div>
				</div>
				
				<div class="col-md-6 text-center">
					<div class="p-5">
					<Link to="/" class="mb-2 mt-4 wow animated fadeInLeft hide-mobile logoText">
							<h3>Florida <span>Driving</span> Permit</h3>
					</Link>
					<br /><br />
					<hr class="opacity-50" />
					<img src={imageAside} alt="Form Aside" class="img-fluid mt-5 w-85" />
					</div>
				</div>
				</div>
			</section>
		</div>
		</div>
		
	
	);
}

export default Register;