import React, { useEffect } from "react";
import { useState } from "react";
import { fetchSecurityQuestions, storeSecurityQuestions } from "../../services/api";
import startnow from "../../images/two-policeman-with-security-lock-and-a-notebook-in(1).png";
import { Navigate, useNavigate } from "react-router-dom";


const Securityquestion=()=>{
	const [securityquestions, setSecurityQuestions]= useState([]);
	const [errors, setErrors] = useState([]);

	const navigate = useNavigate();
	const [questions, setQuestions] = useState([
		{ question: "", answer: "" },
		{ question: "", answer: "" },
		{ question: "", answer: "" }
	  ]);
	  const getquestions=async()=>{
		const token = sessionStorage.getItem('token');
		const response = await fetchSecurityQuestions(token);
		setSecurityQuestions(response.data.questions);
  		}
	  useEffect(()=>{
		if(sessionStorage.getItem('securityCheck') === '1')
		{
			navigate("/course/home")
		}
		sessionStorage.setItem('topicStatus','1');
			getquestions();
	  },[]);

	 

	  const handleQuestionChange = (index, value) => {
		const newQuestions = [...questions];
		newQuestions[index].question = value;
		setQuestions(newQuestions);
	  };
	
	  const handleAnswerChange = (index, value) => {
		const newQuestions = [...questions];
		newQuestions[index].answer = value;
		setQuestions(newQuestions);
	  };
	
	  const validateForm = () => {
		const newErrors = questions.map((q) => ({
		  questionError: !q.question ? "Please select a question." : "",
		  answerError: !q.answer ? "Please provide an answer." : ""
		}));
		setErrors(newErrors);
		return newErrors.every((error) => !error.questionError && !error.answerError);
	  };
	
	  const handleSubmit =async (e) => {
		e.preventDefault();
		if (!validateForm()) {
			return; 
		  }
		const formData = new FormData();
	
		questions.forEach((q, index) => {
		  formData.append(`question${index + 1}`, q.question);
		  formData.append(`answer${index + 1}`, q.answer);
		});
		const token = sessionStorage.getItem('token');
		const userId = sessionStorage.getItem('user_id');
		const response =await storeSecurityQuestions(token,formData,userId);
		if(response.data === true){
			navigate("/course/home");
		}
	  };

	  const getSelectedQuestions = () => {
		return questions.map((q) => q.question).filter(Boolean);
	  };
	

	return(
		<div className="container  min-vh-100 mt-4">
        <div className="row  mt-4">
          	<div class="col-lg-7 ps-lg-5 mt-4">
				<h7 class="fw-bold display-5 mt-4">Select three questions and provide answers for verification.</h7>
					<p class="d-block line-h-150 pt-4">These questions may be randomly asked while you are taking this Course to validate that it is you who is taking the test.</p>
					<form onSubmit={handleSubmit}>
						{questions.map((q, index) => {
						const selectedQuestions = getSelectedQuestions();
						return (
							<div className="mb-3 mt-4" key={index}>
							<label className="form-label">Security Question {index + 1}</label>
							<select
								className="form-select"
								value={q.question}
								onChange={(e) => handleQuestionChange(index, e.target.value)}
								required
							>
								<option value="" disabled>
								Select a question...
								</option>
								{securityquestions
								.filter(
									(question) =>
									!selectedQuestions.includes(question.question) ||
									question.question === q.question
								)
								.map((question, i) => (
									<option key={question.id} value={question.question}>
									{question.question}
									</option>
								))}
							</select>
							{errors[index]?.questionError && (
								<div className="text-danger mt-1">{errors[index].questionError}</div>
							)}
							<input
								type="text"
								className="form-control mt-2"
								placeholder="Your Answer"
								value={q.answer}
								onChange={(e) => handleAnswerChange(index, e.target.value)}
								required
							/>
							{errors[index]?.answerError && (
								<div className="text-danger mt-1">{errors[index].answerError}</div>
							)}
							</div>
						);
						})}
					  <button type="submit" className="btn btn-primary-s mt-3">
						Submit
						</button>
					</form>
				
        	</div>
          <div className="col-md-5 col-lg-5 d-flex justify-content-start  mt-4">
            <div className="mt-4">
              <img className="image-security" src={startnow} width={500} height={500}/>
            </div>
          </div>
        </div>
      
    </div>
	);
}
export default Securityquestion;