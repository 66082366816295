import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Course from "./components/course/Course";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import Homepage from "./components/Homepage";
import Exam from "./components/course/Exam";
function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<Homepage />} />            
          <Route path="/course/*" element={<ProtectedRoute />}>
            <Route path="home" element={<Course component="dashboardhome" />} />
            <Route path="page/:id" element={<Course component="page" />} />
            <Route path="security-question" element= {<Course component="securityquestion"/>}/>
            <Route path="scoreboard" element= {<Course component="scoreboard"/>}/>
            <Route path="course-result-card" element= {<Course component="courseresultcard"/>}/>
            <Route path="take-exam" element= {<Course component="exam"/>}/>
            <Route path="retake-checkout" element={<Course component="retake-checkout"/>}/>
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
