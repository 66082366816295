import React from "react";
import {PaymentElement} from '@stripe/react-stripe-js';
import { useNavigate } from "react-router-dom";

import {Elements,useStripe,useElements} from '@stripe/react-stripe-js';
import { updatePermission } from "../../services/api";



const Retakecheckout = (props)=>{
	const navigate = useNavigate();

	const elements = useElements();
	const stripe = useStripe(); 

	const handleSubmit = async (e) => {
		e.preventDefault();
	
	  props.setIsLoader(true);
	  const token = sessionStorage.getItem("token");
      const user_id = sessionStorage.getItem("user_id");
	  const response = await updatePermission(token,user_id);
	  sessionStorage.setItem('user',JSON.stringify(response.data.user));
		const paymentElement = elements.getElement(PaymentElement);
		if (!paymentElement) {
		return;
		}
		const { error, paymentIntent } = await stripe.retrievePaymentIntent(props.clientSecret);
		
		if (!paymentIntent && !paymentIntent.status === 'succeeded') {
			const { error: confirmError, confirmpaymentIntent: confirmedPaymentIntent } = await stripe.confirmPayment({
				elements,
				confirmParams: {
				  return_url: 'https://your-website.com/payment-success',
				},
				redirect: "if_required",
			  });
			  if (confirmError) {
				props.setErrors({ apiError: error.message });
				props.setIsLoader(false);
				return;
			  } 

		}
		navigate('/course/take-exam');
		// try {
		// 	// const token = sessionStorage.getItem("token");
		// 	// const response = await updateCourse(token,sessionStorage.getItem("user_id"));
		// 	// if (response) {
		// 	// 	sessionStorage.setItem("testResult",0);
		// 	// 	navigate('/course/home');
		// 	// } else {
		// 	//   props.setIsLoader(false);
		// 	// }
		//   } catch (error) {
		// 	console.error('Error during registration:', error);
		// 	props.setIsLoader(false);
		//   }
		
	  };
	  
	return(
		<>
		<PaymentElement />
		<button name="btn" onClick={handleSubmit} class="btn btn-primary-s px-4 py-3 my-4 w-100 text-white">PAY</button>
		</>
	);
}
export default Retakecheckout;