import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import car from '../images/car.png';
import { useNavigate } from "react-router-dom";
import {updateProfile } from "../services/api";
import courseoutline from '../images/course-outline.png'
import featured from '../images/features.png'
import coursespecific from '../images/course-specific.png'
import whatnext from '../images/what-next.png'

const Homepage=(props)=>{
	const userName = sessionStorage.getItem('user_name');
	const [username, setUserName] = useState(sessionStorage.getItem("user_name"));
	const [email, setEmail] = useState(sessionStorage.getItem("user_email"));
	
	const navigate = useNavigate();
	const handleupdateprofile = async () => {
		const token = sessionStorage.getItem("token");
		const userId = sessionStorage.getItem("user_id");
		const formData = new FormData();
		formData.append("user_name", username);
		formData.append("user_email", email);
		formData.append("user_id", userId);
		const response = await updateProfile(token, formData);
		navigate("/course/home");
	  };
	  const handleEmailChange = (e) => {
		setEmail(e.target.value);
	  };
	  const handleNameChange = (e) => {
		setUserName(e.target.value);
	  };
	  const handleRetake =()=>{
		var myModalEl = document.getElementById('editProfileModal');
		var modal = window.bootstrap.Modal.getInstance(myModalEl)
		modal.hide();
		navigate("/course/retake-checkout");
	  }
		const settings = {
		dots: false,
		infinite: true,
		speed: 800,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay:true,
		responsive: [
		  {
			breakpoint: 1224,
			settings: {
			  slidesToShow: 3,
			  slidesToScroll: 3,
			  infinite: true,
			  dots: false
			}
		  },
		  {
			breakpoint: 1000,
			settings: {
			  slidesToShow: 2,
			  slidesToScroll: 2
			}
		  },
		  {
			breakpoint: 680,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1
			}
		  }
		]
	  };
	  const handleLogout = () => {
		sessionStorage.clear();
		navigate("/register");
	  };
	
	return(
		<>
		
		  <div class="secondary-bar mb-3">
				<div class="container">
					<div class="d-md-flex align-items-center justify-content-between">
						<a href="javascript:;" class="mb-2 mt-4 wow animated fadeInLeft hide-mobile logoText">
						<h3>Florida <span>Driving</span> Permit</h3>
						</a>
						{
							sessionStorage.getItem("user_id")?<div class="dropdown mt-3">
							<button
								class="btn btn-secondary dropdown-toggle text-start bg-transparent text-dark p-0 border-0"
								type="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<span class="d-flex gap-3 align-items-center">
								<i class="fa fa-user-circle opacity-50 fa-3x mt-1"></i>
								<span>
									{sessionStorage.getItem("user_name")}
									<br />
									<strong>{sessionStorage.getItem("user_email")}</strong>
								</span>
								</span>
							</button>
							<ul class="dropdown-menu mt-3 p-0 shadow-sm overflow-hidden">
								<li>
								<a
									class="dropdown-item p-3"
									href="javascript:;"
									data-bs-toggle="modal"
									data-bs-target="#editProfileModal"
								>
									<i class="fa fa-user me-2 text-primary-s"></i> Edit Profile
								</a>
								</li>
								<li>
								<a
									class="dropdown-item p-3"
									href="javascript:;"
									data-bs-toggle="modal"
									data-bs-target="#changePasswordModal"
								>
									<i class="fa fa-lock me-2 text-primary-s"></i> Change
									Password
								</a>
								</li>
								<li>
								<a class="dropdown-item p-3" onClick={handleLogout}>
									<i class="fa fa-sign-out me-2 text-primary-s"></i> Logout
								</a>
								</li>
							</ul>
						</div>:<></>
						}
						
					</div>
				</div>
			</div>
			<nav class="navbar navbar-expand-lg bg-light-primary sticky-top w-100 z-3 wow animated fadeInDown border-bottom shadow-sm">
				<div class="container">
					
				<a href="javascript:;" class="wow animated fadeInLeft hide-desktop logoText">
					<h3>Florida <span>Driving</span> Permit</h3>
				</a>
				
				<button class="navbar-toggler btn btn-primary-s py-2 text-white" type="button" data-bs-toggle="collapse"
					data-bs-target="#mainNav" aria-controls="mainNav" aria-expanded="false" aria-label="Toggle navigation"><i
					class="fa fa-bars"></i></button>
				<div class="collapse navbar-collapse py-2" id="mainNav">
					<ul class="navbar-nav me-auto mb-2 mb-lg-0">
					<li class="nav-item">
						<a class="nav-link active" aria-current="page" href="#home">Home</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="#features">Features</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="#course">Our Course</a>
					</li>
					
					<li class="nav-item">
						<a class="nav-link" href="#contact">Contact Us</a>
					</li>
					</ul>
					<form class="d-flex " role="search">
					{userName ? (
						<Link to="/course/home" className="btn btn-secondary-s rounded-start">Go To Dashboard</Link>
					) : (
						<>
							<Link to="/login" className="btn btn-secondary-s rounded-start me-3">Login</Link>
							<Link to="/register" className="btn btn-secondary-s rounded-start">Register</Link>
						</>
					)}
					{/* <input class="form-control shadow-none" type="search" placeholder="Search" aria-label="Search"/>
					<button class="btn btn-secondary-s rounded-start-0 ps-4" type="submit"><i class="fa fa-search"></i></button> */}
					</form>
				</div>
				</div>
			</nav>
			<main data-bs-spy="scroll" data-bs-target="#mainNav" data-bs-smooth-scroll="true" tabindex="0">

			<section class="home-banner position-relative overflow-hidden wow animated fadeInUp" id="home">
			<div class="container h-100 z-2 position-relative">
				<div class="row align-items-center h-100">
				<div class="col-md-6 pe-md-5">
					<h1 class="text-white display-5 my-2 fw-bolder line-h-150 wow animated slideInLeft">Florida Traffic Law and
					Substance Abuse Course</h1>
					<p class="line-h-170 text-white d-block py-t pb-5 wow animated slideInLeft">Online TLSAE course for Teens
					and First Time Drivers</p>
					<a href="javascript:;"
					class="btn btn-primary-s me-5 btn-lg mt-4 py-3 px-4 float-start text-white  wow animated slideInLeft">Discover
					More <i class="fa fa-arrow-right ms-2"></i></a>
					<div class="wow animated slideInLeft"><span class="ripple d-block mt-4 float-start"><a href="javascript:;"
						data-bs-toggle="modal" data-bs-target="#videoModal" class="play-btn"><i
							class="fa fa-play"></i></a></span></div>
				</div>
				<div class="col-md-6">
					<img src={car} alt="Car" class="img-fluid wow animated slideInRight" />
				</div>
				</div>
			</div>
			</section>

			<section class="features py-5" id="features">
			<div class="container">
				<div class="row">
				<div class="col-12 text-center">
					<h3 class="h3 text-uppercase wow animated fadeInDown">SOME AWESOME FEATURES</h3>
					<span class="sep mt-4 mx-auto d-block wow animated fadeInUp"></span>
					<p class="d-block line-h-150 pt-4 wow animated fadeInUp">Our Driving School Provide Some Unique Features For
					Our Leaners</p>
				</div>
				</div>
				<div class="row align-items-stretch mt-4 justify-content-center ps-lg-5">
				<div class="col-lg-4 col-md-6 mt-4 pt-4 px-5">
					<div class="feature-box py-4 pe-4 w-100 gap-4 h-100 wow animated fadeInDown transition">
					<span class="box-icon d-block float-start text-center text-white mt-3 transition"><i
						class="fa fa-car fs-5"></i></span>
					<div class="text-box">
						<h4 class="h5 fw-bold mt-2">Fits Your Learning Style</h4>
						<ul>
						<li class="d-flex gap-2 align-items-start py-2"><i class="fa mt-1 fa-check text-primary-s"></i>
							<p class="d-block line-h-170">Read, Watch and Listen</p>
						</li>
						<li class="d-flex gap-2 align-items-start py-2"><i class="fa mt-1 fa-check text-primary-s"></i>
							<p class="d-block line-h-170">6 Easily Digestible Chapters</p>
						</li>
						<li class="d-flex gap-2 align-items-start py-2"><i class="fa mt-1 fa-check text-primary-s"></i>
							<p class="d-block line-h-170">Illustrative Graphics</p>
						</li>
						<li class="d-flex gap-2 align-items-start py-2"><i class="fa mt-1 fa-check text-primary-s"></i>
							<p class="d-block line-h-170">Educational videos</p>
						</li>
						</ul>
					</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 mt-4 pt-4 px-5">
					<div class="feature-box py-4 pe-4 w-100 gap-4 h-100 wow animated fadeInDown transition">
					<span class="box-icon d-block float-start text-center text-white mt-3 transition"><i
						class="fa fa-calendar-alt fs-5"></i></span>
					<div class="text-box">
						<h4 class="h5 fw-bold mt-2">Fits Your Schedule</h4>
						<ul>
						<li class="d-flex gap-2 align-items-start py-2"><i class="fa mt-1 fa-check text-primary-s"></i>
							<p class="d-block line-h-170">Access the test on your time table</p>
						</li>
						<li class="d-flex gap-2 align-items-start py-2"><i class="fa mt-1 fa-check text-primary-s"></i>
							<p class="d-block line-h-170">Available 24/7</p>
						</li>
						<li class="d-flex gap-2 align-items-start py-2"><i class="fa mt-1 fa-check text-primary-s"></i>
							<p class="d-block line-h-170">Able to come and go as you need to</p>
						</li>
						</ul>
					</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 mt-4 pt-4 px-5">
					<div class="feature-box py-4 pe-4 w-100 gap-4 h-100 wow animated fadeInDown transition">
					<span class="box-icon d-block float-start text-center text-white mt-3 transition"><i
						class="fa fa-car-tunnel fs-5"></i></span>
					<div class="text-box">
						<h4 class="h5 fw-bold mt-2">Ease of Reporting</h4>
						<ul>
						<li class="d-flex gap-2 align-items-start py-2"><i class="fa mt-1 fa-check text-primary-s"></i>
							<p class="d-block line-h-170">Officially approved by the Florida SDMV</p>
						</li>
						<li class="d-flex gap-2 align-items-start py-2"><i class="fa mt-1 fa-check text-primary-s"></i>
							<p class="d-block line-h-170">We’ll send your test completion to the FSDMV</p>
						</li>
						</ul>
					</div>
					</div>
				</div>
				</div>
			</div>

			<div class="bg-light-primary py-4 mt-4 overflow-hidden">
				<div class="row px-4">
				<div class="col-lg-3 col-md-6">
					<div class="d-flex gap-2 align-items-center my-3 justify-content-lg-center"><i
						class="fa fa-check-circle text-primary-s fs-5"></i>
					<p class="d-block line-h-150 fw-bold">Works on computer, tablet &amp; mobile</p>
					</div>
				</div>
				<div class="col-lg-3 col-md-6">
					<div class="d-flex gap-2 align-items-center my-3 justify-content-lg-center"><i
						class="fa fa-check-circle text-primary-s fs-5"></i>
					<p class="d-block line-h-150 fw-bold">Unlimited Retakes</p>
					</div>
				</div>
				<div class="col-lg-3 col-md-6">
					<div class="d-flex gap-2 align-items-center my-3 justify-content-lg-center"><i
						class="fa fa-check-circle text-primary-s fs-5"></i>
					<p class="d-block line-h-150 fw-bold">Affordable Fee</p>
					</div>
				</div>
				<div class="col-lg-3 col-md-6">
					<div class="d-flex gap-2 align-items-center my-3 justify-content-lg-center"><i
						class="fa fa-check-circle text-primary-s fs-5"></i>
					<p class="d-block line-h-150 fw-bold">Secured Site</p>
					</div>
				</div>
				</div>
			</div>
			</section>

			<section class="our-course py-5 overflow-hidden" id="course">
				<div class="container pb-5">
					<ul class="nav nav-pills flex-column flex-sm-row" id="myTab" role="tablist">
					<li class="nav-item flex-sm-fill text-sm-center" role="presentation">
						<button class="nav-link w-100 p-sm-3 p-2 active" id="home-tab" data-bs-toggle="tab"
						data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane"
						aria-selected="true">Course Outline</button>
					</li>
					<li class="nav-item flex-sm-fill text-sm-center" role="presentation">
						<button class="nav-link w-100 p-sm-3 p-2" id="profile-tab" data-bs-toggle="tab"
						data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane"
						aria-selected="false">Features</button>
					</li>
					<li class="nav-item flex-sm-fill text-sm-center" role="presentation">
						<button class="nav-link w-100 p-sm-3 p-2" id="contact-tab" data-bs-toggle="tab"
						data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane"
						aria-selected="false">Course Specifics</button>
					</li>
					<li class="nav-item flex-sm-fill text-sm-center" role="presentation">
						<button class="nav-link w-100 p-sm-3 p-2" id="disabled-tab" data-bs-toggle="tab"
						data-bs-target="#disabled-tab-pane" type="button" role="tab" aria-controls="disabled-tab-pane"
						aria-selected="false">What’s Next?</button>
					</li>
					</ul>
					<div class="row">
					<div class="col-12 text-center mt-4">
					{userName ? (
						<Link to="/course/home" className="btn btn-secondary-s rounded-start">Go To Dashboard</Link>
					) : (
						<Link to="/register" class="btn btn-secondary-s py-3 px-4 text-uppercase mt-4 text-white">Register Here <i
							class="fa fa-arrow-right ms-2"></i></Link>
					)}
					
					</div>
					</div>
					<div class="tab-content pt-5" id="myTabContent">
					<div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
						tabindex="0">
						<div class="row align-items-center">
						<div class="col-lg-5 my-3 position-relative">
							<img src={courseoutline} alt="Course" class="img-fluid wow animated slideInLeft" />
							
						</div>
						<div class="col-lg-7 ps-lg-5">
							<strong class="d-block text-primary-s fw-normal py-2 wow animated slideInRight"><i
								class="fa fa-graduation-cap me-1"></i> Our Course</strong>
							<h3 class="fw-bolder display-5 wow animated slideInRight">Course Outline</h3>
							<ul class="mt-3">
							<li class="d-flex gap-2 align-items-center py-2 wow animated slideInRight"><i
								class="fa fa-angle-right text-primary-s"></i>
								<p class="d-block line-h-150">Chapter 1: Effects of Alcohol and Drugs on your body</p>
							</li>
							<li class="d-flex gap-2 align-items-center py-2 wow animated slideInRight"><i
								class="fa fa-angle-right text-primary-s"></i>
								<p class="d-block line-h-150">Chapter 2: Effects of Alcohol and Drugs on your life</p>
							</li>
							<li class="d-flex gap-2 align-items-center py-2 wow animated slideInRight"><i
								class="fa fa-angle-right text-primary-s"></i>
								<p class="d-block line-h-150">Chapter 3: Effects of Alcohol and Drugs on your community & society
								</p>
							</li>
							<li class="d-flex gap-2 align-items-center py-2 wow animated slideInRight"><i
								class="fa fa-angle-right text-primary-s"></i>
								<p class="d-block line-h-150">Chapter 4: Effects of Alcohol and Drugs while driving</p>
							</li>
							<li class="d-flex gap-2 align-items-center py-2 wow animated slideInRight"><i
								class="fa fa-angle-right text-primary-s"></i>
								<p class="d-block line-h-150">Chapter 5: Major Florida Traffic Laws</p>
							</li>
							<li class="d-flex gap-2 align-items-center py-2 wow animated slideInRight"><i
								class="fa fa-angle-right text-primary-s"></i>
								<p class="d-block line-h-150">Chapter 6: Vulnerable Road Users</p>
							</li>
							</ul>
							
						</div>
						</div>
					</div>
					<div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
						<div class="row align-items-center">
						<div class="col-lg-5 my-3 position-relative">
							<img src={featured} alt="Course" class="img-fluid" />
							
						</div>
						<div class="col-lg-7 ps-lg-5">
							<strong class="d-block text-primary-s fw-normal py-2"><i class="fa fa-graduation-cap me-1"></i> What
							Features You Will Get?</strong>
							<h3 class="fw-bolder display-5">Features</h3>
							<ul class="mt-3">
							<li class="d-flex gap-2 align-items-center py-2"><i class="fa fa-angle-right text-primary-s"></i>
								<p class="d-block line-h-150">Information broken down to understandable chunks</p>
							</li>
							<li class="d-flex gap-2 align-items-center py-2"><i class="fa fa-angle-right text-primary-s"></i>
								<p class="d-block line-h-150">Graphics to help visualize information</p>
							</li>
							<li class="d-flex gap-2 align-items-center py-2"><i class="fa fa-angle-right text-primary-s"></i>
								<p class="d-block line-h-150">Entertaining videos to reinforce messaging</p>
							</li>
							<li class="d-flex gap-2 align-items-center py-2"><i class="fa fa-angle-right text-primary-s"></i>
								<p class="d-block line-h-150">Quiz questions to help prepare for Final Exam</p>
							</li>
							</ul>
							
						</div>
						</div>
					</div>
					<div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
						<div class="row align-items-center">
						<div class="col-lg-5 my-3 position-relative">
							<img src={coursespecific} alt="Course" class="img-fluid" />
						</div>
						<div class="col-lg-7 ps-lg-5">
							<strong class="d-block text-primary-s fw-normal py-2"><i class="fa fa-graduation-cap me-1"></i> Choose
							Wisely</strong>
							<h3 class="fw-bolder display-5">Course Specifics</h3>
							<p class="d-block line-h-150 pt-4">The course contains 4 hours of required content. At the end of the
							course there is a final exam consisting of 40 multiple choice questions. You must score at least 80%
							to pass (32 out of 40 questions). Most people pass on their first try, but you can retake the exam as
							many times as you need to get a passing score. <br /><br />Upon successful completion of the test we
							will send your results to the Florida Department of Highway Safety and Motor Vehicles which should
							appear in their system within two business days.<br /><br />We will also email you your Receipt of
							Completion to the email account you registered with.. Alternatively you can also print a copy online
							from your account.</p>
							
						</div>
						</div>
					</div>
					<div class="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab" tabindex="0">
						<div class="row align-items-center">
						<div class="col-lg-5 my-3 position-relative">
							<img src={whatnext} alt="Course" class="img-fluid" />
							
						</div>
						<div class="col-lg-7 ps-lg-5">
							<strong class="d-block text-primary-s fw-normal py-2"><i class="fa fa-graduation-cap me-1"></i> Coming
							Up?</strong>
							<h3 class="fw-bolder display-5">What’s Next?</h3>
							<p class="d-block line-h-150 pt-4">The FTLSAE is the first step to getting your license. After
							successfully completing this test you will need to get your Learner’s License which requires:</p>
							<ul class="mt-3">
							<li class="d-flex gap-2 align-items-center py-2"><i class="fa fa-angle-right text-primary-s"></i>
								<p class="d-block line-h-150">You to be at least 15 years old</p>
							</li>
							<li class="d-flex gap-2 align-items-center py-2"><i class="fa fa-angle-right text-primary-s"></i>
								<p class="d-block line-h-150">Successfully completed the FTLSAE</p>
							</li>
							<li class="d-flex gap-2 align-items-center py-2"><i class="fa fa-angle-right text-primary-s"></i>
								<p class="d-block line-h-150">Pass the permit test (See the <a class="text-primary-s"
									href="https://www.flhsmv.gov/driver-licenses-id-cards/licensing-requirements-teens-graduated-driver-license-laws-driving-curfews/"
									target="_blank">Florida DMV</a> for details)</p>
							</li>
							<li class="d-flex gap-2 align-items-center py-2"><i class="fa fa-angle-right text-primary-s"></i>
								<p class="d-block line-h-150">Pass the vision test</p>
							</li>
							</ul>
						</div>
						</div>
					</div>
					</div>

				</div>
			</section>
			</main>

			<footer class="bg-secondary-s py-5 text-center">
				<div class="container pt-3">
				<div class="row">
					<div class="col-lg-3 col-md-2"></div>
					<div class="col-lg-6 col-md-8 text-center text-white">
					<ul class="d-flex gap-4 justify-content-center common-links wow animated fadeInDown">
						<li class="mt-4"><a href="javascript:;" class="text-white fs-11">Privacy Policy</a></li>
						<li class="mt-4"><a href="javascript:;" class="text-white fs-11">Terms &amp; Conditions</a></li>
						<li class="mt-4"><a href="javascript:;" class="text-white fs-11">FAQs</a></li>
						<li class="mt-4"><a href="javascript:;" class="text-white fs-11">Contact Us</a></li>
					</ul>
					<ul class="d-flex gap-4 justify-content-center social wow animated fadeInDown">
						<li class="mt-4"><a href="javascript:;"><i class="fab fa-facebook fs-5"></i></a></li>
						<li class="mt-4"><a href="javascript:;"><i class="fab fa-twitter fs-5"></i></a></li>
						<li class="mt-4"><a href="javascript:;"><i class="fab fa-youtube fs-5"></i></a></li>
						<li class="mt-4"><a href="javascript:;"><i class="fab fa-instagram fs-5"></i></a></li>
					</ul>
					</div>
					<div class="col-lg-3 col-md-2"></div>
				</div>
				<br />
				<hr class="wow animated fadeInDown" />
				<small class="d-block text-white pt-4 fs-11 wow animated fadeInDown">Florida Driving Permit &copy; Copyrights
					2024. Alll Rights Reserved</small>
				</div>
			</footer>

			<div
            class="modal fade"
            id="editProfileModal"
            tabindex="-1"
            aria-labelledby="editProfileModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="editProfileModalLabel">
                    Edit Profile
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body p-4">
                  <form>
                    <h4 class="h4 mb-0">Contact Details</h4>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-floating mt-4">
                          <input
                            type="text"
                            class="form-control"
                            id="name"
                            placeholder={sessionStorage.getItem("user_name")}
                            onChange={handleNameChange}
                            value={username}
                          />
                          <label for="name">User Name</label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-floating mt-4">
                          <input
                            type="email"
                            class="form-control"
                            id="email"
                            placeholder={sessionStorage.getItem("user_email")}
                            onChange={handleEmailChange}
                            value={email}
                          />
                          <label for="email">Email Address</label>
                        </div>
                      </div>
					  
					  {sessionStorage.getItem("user_id") && JSON.parse(sessionStorage.getItem('user')).retake_permission === '0'?
                          <div class="col-md-6 mt-4">
                          <label for="email">You have used your free retake</label>
                          <button
                          type="button"
                          class="btn btn-primary-s mt-3"
                          onClick={handleRetake}
                          >
                          <i class="fa fa-save me-1"></i>
                            Recharge Retake
                          </button>
                          </div>:<></>
                      }
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary-s"
                    onClick={handleupdateprofile}
                  >
                    <i class="fa fa-save me-1"></i> Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
		</>
	);

}
export default Homepage;