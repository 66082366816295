import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../../../images/logo.png";

const Header = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState();
  const [useremail, setUseremail] = useState();


  const handleResultCard=()=>{
    navigate('/course/course-result-card');
  }

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    const user_name = sessionStorage.getItem("user_name");
    const user_email = sessionStorage.getItem("user_email");
    setUsername(user_name);
    setUseremail(user_email);
  });

  return (
    <>
      <nav class="top-nav dashb fixed-top z-5 w-100 d-block bg-white py-3 border-bottom">
        <div class="container">
          <div class="d-md-flex justify-content-between align-items-center">
          <Link to="/" class="mb-2 mt-4 wow animated fadeInLeft hide-mobile logoText">
						<h3>Florida <span>Driving</span> Permit</h3>
						</Link>
            <h4 class="line-h-150 fs-5 fw-bold px-md-4">
              Florida First-Time Driver (TLSAE / Drug and Alcohol) Course
            </h4>
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle text-start bg-transparent text-dark p-0 border-0"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="d-flex gap-3 align-items-center">
                  <i class="fa fa-user-circle opacity-50 fa-3x mt-1"></i>
                  <span>
                    {sessionStorage.getItem("user_name")}
                    <br />
                    <strong>{sessionStorage.getItem("user_email")}</strong>
                  </span>
                </span>
              </button>
              <ul class="dropdown-menu mt-3 p-0 shadow-sm overflow-hidden">
                {JSON.parse(sessionStorage.getItem('user')).retake_status !== '0' &&
                    <li>
                      <a  href="javascript:;" class="dropdown-item p-3" onClick={handleResultCard}>
                      <i class="fa-solid fa-graduation-cap text-primary-s"></i> Course Result
                      </a>
                    </li>
                }
               
                <li>
                  <a
                    class="dropdown-item p-3"
                    href="javascript:;"
                    data-bs-toggle="modal"
                    data-bs-target="#editProfileModal"
                  >
                    <i class="fa fa-user me-2 text-primary-s"></i> Edit Profile
                  </a>
                </li>
                <li>
                  <a
                    class="dropdown-item p-3"
                    href="javascript:;"
                    data-bs-toggle="modal"
                    data-bs-target="#changePasswordModal"
                  >
                    <i class="fa fa-lock me-2 text-primary-s"></i> Change
                    Password
                  </a>
                </li>
                
                <li>
                  <a class="dropdown-item p-3" onClick={handleLogout}>
                    <i class="fa fa-sign-out me-2 text-primary-s"></i> Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Header;
