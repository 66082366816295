import React from "react";
import { useState, useEffect } from "react";
import { getExam } from "../../services/api";
import { useNavigate } from "react-router-dom";
import Loader from '../../images/loader.png';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { submitExam } from "../../services/api";
import { Link } from "react-router-dom";
import startnow from "../../images/quiz_pic.png";


const Exam = ()=>{
	const [loading, setLoading] = useState(true);
	const [finalanswers, setFinalanswers] = useState({});
  const [finalquestions, setFinalquestions] = useState([]);
  const [quizerror, setQuizError] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentquiztime, setCurrentquiztime] = useState();
  const [splashscreen, setSplashscreen] = useState(true);

	const navigate = useNavigate();

	useEffect(()=>{
    console.log(JSON.parse(sessionStorage.getItem('user')).retake_status !== '0',JSON.parse(sessionStorage.getItem('user')).retake_permission !== '0'  );
		if(JSON.parse(sessionStorage.getItem('user')).retake_permission === '0' ){
			navigate('/course/course-result-card');
		}
		getQuestions();
	},[]);

	const cancelQuiz=()=>{
		setCurrentQuestionIndex(0);
		setFinalquestions([]);
		navigate('/course/home');
	}
  const submitfinalquiz=async()=>{
    console.log(Object.keys(finalanswers).length);
    if(Object.keys(finalanswers).length !== finalquestions.length){
      setQuizError(true);
      return;
    }
  
    const token = sessionStorage.getItem("token");
    const user_id = sessionStorage.getItem("user_id");
    const formdata = new FormData();
    formdata.append('user_id', user_id);
    formdata.append('finalanswers',JSON.stringify(finalanswers));
    setFinalanswers({});
    const response = await submitExam(token, formdata);
    if(response){
      sessionStorage.removeItem("topicStatus");
      sessionStorage.removeItem("lastTopic");
      sessionStorage.setItem("testResult",response.data.score);
      sessionStorage.setItem("total",response.data.total);
      sessionStorage.setItem("user",JSON.stringify(response.data.user));
      navigate("/course/scoreboard");
    }
    }
		
	const handleUserFinalanswer = async (event, questionId) => {
		const selectedAnswer = event.target.value;
		setFinalanswers((prevAnswers) => ({
		  ...prevAnswers,
		  [questionId]: selectedAnswer, 
		}));
	  };

	const getQuestions=async()=>{
		const token = sessionStorage.getItem("token");
		const user_id = sessionStorage.getItem("user_id");
		const response = await getExam(token,user_id);
		if(response){
			setFinalquestions(response.data.questions);
			setLoading(false);
		}
	}
  const handleExam=()=>{
    setSplashscreen(false);
  }
  const handleHome=()=>{
		navigate("/course/home");
	}

  if(splashscreen){
    return( <div className="container ">
          <div className="row">
            <div class="col-lg-7 ps-lg-5 mt-4">
              <div className="p-4 mt-4">
                <div className="heading mt-4 d-flex justify-content-center">
                  <span className="retake-heading mt-4">Retake Exam</span>
                </div>
                <div className="score-heading d-flex justify-content-center mt-4">
                  <span class="splash-screen-text mt-4">The retake exam will be presented in one, 40 question exam.   You must complete this test in one session.  If you choose to leave before completing this test you will be required to restart the full exam.<br></br>Click on “Exam Retake” when you are ready to begin this process.</span><br></br>
                  <span></span>
                </div>
                <div className="action-buttons  d-flex justify-content-around">
                <button  data-bs-toggle="modal" data-bs-target="#cancelexamModal" class="retake-btn">
                  <i class="fa fa-arrow-left fs-12"></i> Exit
                </button>
                <button onClick={handleExam} className="retake-btn">Exam Retake</button>
                <button onClick={handleHome} className="retake-btn">HOME</button>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 d-flex justify-content-start  mt-4">
              <div className="mt-4">
                <img className="image-security" src={startnow} width={500} height={500}/>
              </div>
            </div>
          </div>

        </div>);
  }

	return(<>{loading?<div class="loader position-fixed top-0 bottom-0 start-0 end-0 bg-dark">
		<span class="position-absolute top-50 start-50 translate-middle"><img src={Loader} alt="Loader"  /></span>
		</div>:<>
		<div class="d-flex justify-content-center">
      <div className="final-screen text-align-center">
      {quizerror?(<><span className="text-danger alert-error">Select at least One Option</span><br></br><br></br></>):(<></>)} 
      {finalquestions.map((question, index) => (
          index === currentQuestionIndex && ( // Only render the current question
            <div key={index} className="mt-4">
              <span className="question">{question.question}</span>
              <br />
              <div className="d-flex mt-4 justify-content-around">
                {question.option_one && (
                  <>
                    <input
                      type="radio"
                      className="d-none"
                      id={`${question.option_one}${question.id}`}
                      name={`question_${question.id}`}
                      onChange={(e) => handleUserFinalanswer(e, question.id)}
                      value="A"
                    />
                    <label
                      className="customTab"
                      htmlFor={`${question.option_one}${question.id}`}
                    >
                      {question.option_one}
                    </label>
                  </>
                )}
                {question.option_two && (
                  <>
                    <input
                      type="radio"
                      className="d-none"
                      id={`${question.option_two}${question.id}`}
                      name={`question_${question.id}`}
                      onChange={(e) => handleUserFinalanswer(e, question.id)}
                      value="B"
                    />
                    <label
                      className="customTab"
                      htmlFor={`${question.option_two}${question.id}`}
                    >
                      {question.option_two}
                    </label>
                  </>
                )}
              </div>
              <div className="d-flex mt-4 justify-content-around">
                {question.option_three && (
                  <>
                    <input
                      type="radio"
                      className="d-none"
                      id={`${question.option_three}${question.id}`}
                      name={`question_${question.id}`}
                      onChange={(e) => handleUserFinalanswer(e, question.id)}
                      value="C"
                    />
                    <label
                      className="customTab"
                      htmlFor={`${question.option_three}${question.id}`}
                    >
                      {question.option_three}
                    </label>
                  </>
                )}
                {question.option_four && (
                  <>
                    <input
                      type="radio"
                      className="d-none"
                      id={`${question.option_four}${question.id}`}
                      name={`question_${question.id}`}
                      onChange={(e) => handleUserFinalanswer(e, question.id)}
                      value="D"
                    />
                    <label
                      className="customTab"
                      htmlFor={`${question.option_four}${question.id}`}
                    >
                      {question.option_four}
                    </label>
                  </>
                )}
              </div>
            </div>
          )
        ))}

        <div className="container mt-4 quiz-timer">
          <div className="row mt-3">
            <div className="col-lg-2 mt-4">
              {/* <button onClick={cancelQuiz} className="btn btn-secondary-s mb-3 btn-lg w-90 text-white">
                <i className="fa fa-arrow-left fs-12"></i> Exit
              </button> */}
              <button  data-bs-toggle="modal" data-bs-target="#cancelexamModal" class="btn btn-secondary-s mb-3 btn-md  w-90 text-white">
                <i class="fa fa-arrow-left fs-12"></i> Exit
              </button>
            </div>

            <div className="col-lg-8 col-md-6 mt-4">
              <div className="d-flex justify-content-between gap-4">
                {/* <button
                  className="btn btn-secondary-s text-white btn-lg w-100 rounded-2"
                  onClick={() => setCurrentQuestionIndex((prev) => Math.max(prev - 1, 0))}
                  disabled={currentQuestionIndex === 0}
                >
                  Previous
                </button> */}
                <button
                  className="btn btn-secondary-s text-white btn-lg w-100 rounded-2"
                  onClick={() => setCurrentQuestionIndex((prev) => Math.min(prev + 1, finalquestions.length - 1))}
                  disabled={currentQuestionIndex === finalquestions.length - 1 || (currentQuestionIndex === Object.keys(finalanswers).length)}
                >
                  Next
                </button>
              </div>
            </div>

            {/* <div className="col-2">
              <CountdownCircleTimer
                isPlaying
                duration={5400}
                size={100}
                colors="#0BA8CF"
                onUpdate={(remainingTime) => {
                  setCurrentquiztime(remainingTime);
                  if (remainingTime === 0) {
                    cancelQuiz();
                  }
                }}
              >
                {() => {
                  const minutes = Math.floor(currentquiztime / 60);
                  const seconds = currentquiztime % 60;
                  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
                }}
              </CountdownCircleTimer>
            </div> */}
          </div>
        </div>

        {currentQuestionIndex === finalquestions.length - 1 && (
          <div className="container mt-4">
            <button
              className="btn btn-primary-s text-white btn-lg w-100 rounded-2"
              onClick={submitfinalquiz}
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </div>
  
		</>}
	</>);
}

export default Exam;