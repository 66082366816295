import axios from "axios";

const API = axios.create({
  baseURL: "https://fdp.deviotech.com/api",
  // baseURL: "http://localhost:8000/api"
 
});

export const updatePermission=(token, user_id)=>
  API.get(`/update-permission/${user_id}`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const fetchCourse = (token, user_id) =>
  API.get(`/get-course/${user_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateCourse = (token, user_id)=>
  API.get(`/update-course/${user_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getQuestion = (token, topic_id,u_id, lastStatus)=>
  API.get(`/get-question/${topic_id}/${u_id}/${lastStatus}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  export const getFinalquestions = (token, topic_id,u_id)=>
    API.get(`/get-final-question/${topic_id}/${u_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    export const getExam = (token, user_id) =>
      API.get(`/get-exam/${user_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

export const fetchSecurityQuestions = (token) =>
  API.get(`/get-security-questions`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getTestResults = (token, user_id) =>
  API.get(`/get-results/${user_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const downloadPdf = (token, user_id) =>
  API.get(`/download-pdf/${user_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  });

export const storeSecurityQuestions = (token,formData,userId) =>
  API.post(`/store-security-questions/${userId}`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });

export const courseDetail = (token) =>
  API.get(`/course-detail`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchTopics = (token, sectionId) =>
  API.get(`/get-topics/${sectionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchPages = (token, topicId, user_id) =>
  API.get(`/get-pages/${topicId}/${user_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const fetchRemainingTime = (token, topicId, user_id) =>
  API.get(`/get-topic-time/${topicId}/${user_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateTopic = (token, pageId, user_id, remainingTime) =>
  API.get(`/update-topic-time/${pageId}/${user_id}/${remainingTime}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateTopicStatus = (token, id, user_id, user_ans ,q_id) =>
  API.get(`/update-topic-status/${id}/${user_id}/${user_ans}/${q_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

 

  export const submitFinalquiz = (token,formdata) =>
    API.post(`/submit-final-quiz`, formdata, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    export const submitExam= (token,formdata) =>
      API.post(`/submit-exam`, formdata, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
  

export const checkPage = (token, pageId, user_id) =>
  API.get(`/check-page/${pageId}/${user_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const checkTopic = (token, topicId, user_id) =>
  API.get(`/check-topic/${topicId}/${user_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getTopics = (token, user_id) =>
  API.get(`/get-topics-progress/${user_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateStatus = (token, topicId, user_id) =>
  API.get(`/update-status/${topicId}/${user_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const updateProfile = (token, formData) =>
  API.post(`/update-profile`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });


export const getPaymentIntent = (id) => API.get(`/payment-intent`);
export const getPaymentRetakeIntent = (id) => API.get(`/payment-retake-intent`);

export const userValidate = (formData) => API.post("/user-validate", formData);
export const register = (formData) => API.post("/user-register", formData);
export const login = (formData) => API.post("/user-login", formData);
