import React, {useEffect, useState} from "react";
import { useNavigate,Link } from "react-router-dom";
import { login } from "../../services/api";
import imageAside from "../../images/form-aside.png";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import Loader from '../../images/loader.png';


const Login=()=>{
	const navigate = useNavigate();
	const [isloader , setIsLoader] = useState(false);
	useEffect(() => {
	  const isAuthenticated = sessionStorage.getItem('token');
	  // If token exists, redirect to /courses
	  if (isAuthenticated) {
		navigate('/course/home');
	  }
	}, []);
	const [formData, setFormData] = useState({
		email: '',
		password: ''
	  });
	const [errors, setErrors] = useState({});
  	const [successMessage, setSuccessMessage] = useState('');
	  const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData({
		  ...formData,
		  [name]: type === 'checkbox' ? checked : value
		});
	  };
	  const validate = () => {
		let errors = {};
		if (!formData.email) {
		  errors.email = 'Email is required';
		} else if (!/\S+@\S+\.\S+/.test(formData.email)) {
		  errors.email = 'Email address is invalid';
		}
		if (!formData.password) {
		  errors.password = 'Password is required';
		} else if (formData.password.length < 6) {
		  errors.password = 'Password must be at least 6 characters';
		}
		setErrors(errors);
	
		return Object.keys(errors).length === 0;
	  };
	  const handleSubmit = async (e) => {
		e.preventDefault();
		if (validate()) {
		  try {
			setIsLoader(true);
			const response = await login(formData);
			if(response.data.token){
				setSuccessMessage('Login successful!');
				sessionStorage.setItem('token', response.data.token);
				sessionStorage.setItem('user_id', response.data.user.id);
				sessionStorage.setItem('user_name', response.data.user.name);
				sessionStorage.setItem('user_email', response.data.user.email);
				sessionStorage.setItem('user',JSON.stringify(response.data.user));
			setIsLoader(false);
				navigate('/course/home');
			}
			else{
				setIsLoader(false);
				setErrors({ apiError: response.data.error });
			}
		
		  } catch (error) {
			setIsLoader(false);
			console.error('Error during registration:', error);
			setErrors({ apiError: error.response.data.error });
		  }
		}
	  };

	  const responseMessage = async(response) => {
		const decodedToken = jwtDecode(response.credential);
		if(decodedToken){
		   const formData = new FormData(); 			
		   formData.append('email',decodedToken.email);
		   formData.append('sub',decodedToken.sub);
		   formData.append('name',decodedToken.name);
		   console.log(formData);
		//    const response = await googleLogin(formData);
		//    if(response.data.token){
		// 	   setSuccessMessage('Login successful!');
		// 	   sessionStorage.setItem('token', response.data.token);
		// 	   sessionStorage.setItem('user_id', response.data.user.id);
		// 	   sessionStorage.setItem('user_name', response.data.user.name);
		// 	   sessionStorage.setItem('user_email', response.data.user.email);
			   
		// 	   const redirectPath = sessionStorage.getItem('redirectPath') || '/';
		// 	   sessionStorage.removeItem('redirectPath');
		// 	   navigate(redirectPath);
		//    }
		//    else{
		// 	   setErrors({ apiError: response.data.error });
		//    }
		}
   };
   const errorMessage = (error) => {
	   console.log(error);
   };



	return (
		<div class="login-register">
			{isloader &&
		<div class="loader position-fixed top-0 bottom-0 start-0 end-0 bg-dark">
		<span class="position-absolute top-50 start-50 translate-middle"><img src={Loader} alt="Loader"  /></span>
		</div>
		}
			<div class="container d-flex align-items-center mvh-100">
			<Link className="back-btn mr-3 " to="/"><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>

			<section class="login-registration rounded-3 overflow-hidden shadow bg-light">
				
				<div class="row align-items-stretch">

				<div class="col-md-6 bg-secondary-s text-center text-white">

					<div class="p-5">

					<h1 class="h3">Let's Get Started</h1>
					<p>Login to continue to Florida Driving Permit <br></br>
					{errors.apiError && <span className="text-danger">{errors.apiError}</span>}<br></br>
					
					{successMessage && <span className="text-success">{successMessage}</span>}</p>
					<form class="text-dark py-4" onSubmit={handleSubmit}>
						<div class="row">
						{/* <div class="col-lg-6">
							<div class="form-floating mt-4">
							<input type="text" class="form-control" id="name" name="name" value={formData.name}  onChange={handleChange} placeholder=" " />
							<label for="name">Name</label>
							{errors.name && <span className="text-danger">{errors.name}</span>}

							</div>
						</div> */}
						<div class="col-lg-6">
							<div class="form-floating mt-4">
							<input type="email" class="form-control" id="email" name="email"  autoComplete="email" value={formData.email} onChange={handleChange}  placeholder=" " />
							<label for="email">Email</label>
							{errors.email && <span className="text-danger">{errors.email}</span>}
							</div>
						</div>
						<div class="col-lg-6">
							<div class="form-floating mt-4">
							<input type="password" class="form-control" id="password" name="password" value={formData.password} onChange={handleChange}  placeholder=" " />
							<label for="password">Password</label>
							{errors.password && <span className="text-danger">{errors.password}</span>}
							</div>
						</div>
						<div class="col-12">
							<button name="btn" class="btn btn-primary-s px-4 py-3 my-4 w-100 text-white">LOGIN</button>
							<p class="text-white">OR<br /></p>
						</div>
						<div class="col-lg-12 mt-3">
							<GoogleLogin class="btn btn-danger px-4 py-2 my-4 w-100" onSuccess={responseMessage} onError={errorMessage} />
							{/* <button name="btn" class="btn btn-danger px-4 py-2 my-4 w-100" type="button"><i class="fab fa-google me-2"></i> GOOGLE LOGIN</button> */}
						</div>
						
						<div class="col-12 mt-3">
							<p class="text-white">Don't have an account ? <Link to="/register" class="text-primary-s fw-bold">REGISTER</Link></p>
						</div>
						</div>
					</form>
					</div>
				</div>
				<div class="col-md-6 text-center">
					<div class="p-5">
						<Link to="/" class="mb-2 mt-4 wow animated fadeInLeft hide-mobile logoText">
							<h3>Florida <span>Driving</span> Permit</h3>
						</Link>
					<br /><br />
					<hr class="opacity-50" />
					<img src={imageAside} alt="Form Aside" class="img-fluid mt-5 w-85" />
					</div>
				</div>
				</div>
			</section>
			</div>
		</div>
	);
}
export default Login;