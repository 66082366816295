import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import {Elements,useStripe,useElements} from '@stripe/react-stripe-js';
import Loader from '../../images/loader.png';
import {loadStripe} from '@stripe/stripe-js';
import { useNavigate } from "react-router-dom";
import { getPaymentRetakeIntent } from "../../services/api";
import Checkout from './Retakecheckout';
import startnow from "../../images/two-policeman-with-security-lock-and-a-notebook-in(1).png";

const stripePromise = loadStripe('pk_test_51Op5bKJnwLc81YFtQcBbFAsiax0GueRMQee7HyGM36dlJHGza0y7mPs9BGW3g1Kqplh5hzddKqn12sPUqwqh15U800RTVQ1g0E'
);
const Scoreboard =()=>{
	const navigate = useNavigate();
	const [clientSecret, setClientSecret] = useState("");
	const [isloader , setIsLoader] = useState(false);
	const [retake , setRetake] = useState(false);
	
	const initiateStripe=async()=>{
		const response = await getPaymentRetakeIntent();
		setClientSecret(response.data);
		setRetake(true);
	}

	const handleExam=()=>{
		sessionStorage.setItem("examAllow",true);
		navigate('/course/take-exam')
	}

	const handleResultCard=()=>{
		navigate("/course/course-result-card");
	}
	
	const handleHome=()=>{
		navigate("/course/home");
	}


	return(
		<div className="container  min-vh-100 mt-4">
			{isloader &&
		<div class="loader position-fixed top-0 bottom-0 start-0 end-0 bg-dark">
		<span class="position-absolute top-50 start-50 translate-middle"><img src={Loader} alt="Loader"  /></span>
		</div>
		}
        <div className="row  mt-4">
			{retake?(<div class="col-lg-7 ps-lg-5 mt-4 p-4">
				<Elements stripe={stripePromise} options={{ clientSecret }}>
					<Checkout clientSecret = {clientSecret} setIsLoader={setIsLoader}/> 
				</Elements>
			</div>):(<>{sessionStorage.getItem("testResult") >= 80 ?(
				<div class="col-lg-7 ps-lg-5 mt-4">
					<div className="p-4">
						<div className="score-heading d-flex justify-content-center">
							<span class=" mt-4">Congratulations!  You have passed the Florida TLSAE course. You answered {sessionStorage.getItem("total")} of 10 questions correctly for a score of {sessionStorage.getItem("testResult")}%.</span><br></br><br></br>
						</div>
						<div className="failed-text mt-4 d-flex justify-content-center">
							 <span className="text-success">Your information will be sent to the Florida HSMV. Good Luck on your Permit test!</span>
						</div>
						<div className="score  d-flex justify-content-center">
							<div className="score-box">
								<span className="text-success">{sessionStorage.getItem("testResult")} %</span>
								<br></br>
							</div>
						</div>
						<div className="action-buttons  d-flex justify-content-around">
							{/* <button onClick={initiateStripe} className="retake-btn">RETAKE</button> */}
							<button onClick={handleHome} className="retake-btn">HOME</button>
						</div>
					</div>
        	</div>):(
				<div class="col-lg-7 ps-lg-5 mt-4">
					<div className="p-4">
						<div className="score-heading d-flex justify-content-center">
							<span class=" mt-4">We’re sorry but you did not pass the Florida TLSAE course.<br></br>You answered {sessionStorage.getItem("total")} of 10 questions correctly for a score of {sessionStorage.getItem("testResult")}%.</span><br></br>
						</div>
						<div className="failed-text mt-4 d-flex justify-content-center">
							{JSON.parse(sessionStorage.getItem("user")).retake_status === '1'?<span className="text-danger">You have the opportunity to retake the exam for free.  The retake exam will be presented in one 40 question exam.<br></br>  Click on “Exam Retake” when you are ready to begin this process.</span>:
								<span className="text-danger">You have used your free retake. You may purchase an additional exam opportunity for a fee of $X. If you wish to do this now, select “Purchase Retake”. If you wish to do this later, you can purchase the retake by going to your Profile and selecting Course Results.</span>}
							{/* {JSON.parse(sessionStorage.getItem("user")).retake_status === '1'?<span className="text-danger">You have the opportunity to retake the exam for free.  The retake exam will be presented in one 40 question exam.<br></br>  Click on “Exam Retake” when you are ready to begin this process.</span>:
								<span className="text-danger">You have used your free retake.  You may purchase an additional exam opportunity for a fee of $X.  When you are ready to retake this exam you may purchase the retake by going to your Profile and selecting Course Results.</span>} */}
						</div>
					
						<div className="action-buttons  d-flex justify-content-around">
						{JSON.parse(sessionStorage.getItem("user")).retake_permission === '1'?<button onClick={handleExam} className="retake-btn">RETAKE</button>:<></>}
							<button onClick={handleHome} className="retake-btn">Review Course Material</button>
							<button onClick={handleResultCard} className="retake-btn">Purchase Retake</button>
						</div>
					</div>
        	</div>)}</>)}
			
          <div className="col-md-5 col-lg-5 d-flex justify-content-start  mt-4">
            <div className="mt-4">
              <img className="image-security" src={startnow} width={500} height={500}/>
            </div>
          </div>
        </div>
      
    </div>
	);
}

export default Scoreboard;